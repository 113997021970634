import React from "react"
import { Link } from "gatsby"

const ResourcesAndSupportSubmenu = () => {
  return (
    <>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Request Resources" activeClassName="isActive" partiallyActive={true} to="/resources/request-resources/">Request Resources <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Downloadable Resources" activeClassName="isActive" partiallyActive={true} to="/resources/downloadable-resources/">Downloadable Resources & Videos &rsaquo;</Link></li>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Neuroblastoma Support and Advocacy Groups" activeClassName="isActive" partiallyActive={true} to="/resources/support-groups/">Neuroblastoma Support and Advocacy Groups <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Skivolos Kid Zone" activeClassName="isActive" partiallyActive={true} to="/resources/skivolos-kid-zone/">Skivolo's Kid Zone <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Questions to Ask Your Doctor" activeClassName="isActive" partiallyActive={true} to="/resources/doctor-discussion-guide/">Questions to <br className="show-for-large"/>Ask Your Doctor <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Glossary of Terms" activeClassName="isActive" partiallyActive={true} to="/resources/glossary/">Glossary of Terms <span className="np-arrow">&rsaquo;</span></Link></li>
    </>
  )
}

export default ResourcesAndSupportSubmenu
