import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import FancyImage from "../../components/template-partials/global-components/fancy-image"
import ResourcesAndSupportSubmenu from "../../components/template-partials/resources-and-support/resources-and-support-submenu"

import { ExploreCard } from "../../components/template-partials/global-components/footer-cards"

import headingBg from "../../images/7.0-headerbg.jpg"
import headingRound from "../../images/resources-and-support-nav-image.jpg"

import drQuestions from "../../images/questionstoaskyourdr.jpg"
import fastFacts from "../../images/fastfacts_thumbnail.jpg"
import NeuroblastomaGuide from "../../images/Braving_Neuroblastoma_Resource_Guide.png"

import bigAdventures from "../../images/7.4_the-big-adventures-of-little-skivolo.jpg"
import antiBodyBook from "../../images/3.4.1_antibodybook.jpg"
import bookOfFun from "../../images/7.4_little-skivolo-big-book-of-fun.jpg"
import zara from "../../images/Zaratakesoff_v23.jpg"
import thumbResource from "../../images/thumb-coping-with-childhood.png"

import tvYellow from "../../images/tv-yellow.png"
import tvLBlue from "../../images/tv-lblue.png"
import tvBlue from "../../images/tv-blue.png"
import tvLime from "../../images/tv-lime.png"
import tvOrange from "../../images/tv-orange.png"
import tvPink from "../../images/tv-pink.png"



import managing from "../../images/7.3-managing-treatment.jpg"
import VideoPlayer from "../../components/VideoPlayer"

import thumbJourneyCalendar from "../../images/thumb_treatment_journal.png"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faExternalLinkAlt,
  faLink,
  faDownload,
} from "@fortawesome/free-solid-svg-icons"

//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from "../../components/variables.module.scss"

// Youtube Videos
import { videos } from "../../components/video-data/resource-videos.js"


const DownloadableResourcesPage = () => {
  const [videoId, setVideoId] = useState('rANU9kgFCqk?si=yVIA4fKpyrAMn0r1')
  const [videoTitle, setVideoTitle] = useState('Caring For The Caregiver')
  
  return (
    <Layout>
      <SEO
        title="Helpful Neuroblastoma Resources & Videos"
        description="Helpful resources for you and your child, including tips for organizing important medical information, questions to ask your doctor, and storybooks about Skivolo – a young red panda with high-risk neuroblastoma."
      />
      <InteriorPageHeader
        backgroundImage={headingBg}
        roundImage={headingRound}
        alt="Helpful Resources"
      >
        <h1 className="section__heading section__heading--green h1">
          Downloadable Resources <br className="show-for-large" />& Videos
        </h1>
      </InteriorPageHeader>
      {/* style={{backgroundColor: SassVars.viridian}} */}
      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`Resources`}>
              <ResourcesAndSupportSubmenu />
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons />
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <h2 className="h6 open-sans">
                  From diagnosis to treatment and beyond, here you’ll find the
                  resources you need to help you and your child along the
                  treatment journey.
                </h2>
                <h3 className="h3 jost-med tc-viridian lg_mt-2">
                  Jump to a section below:
                </h3>
                <ul className="ul--standard lg_mt-1">
                  <li>
                    <a href="#downloadables">
                      <FontAwesomeIcon icon={faLink} /> Downloadable resources
                    </a>
                  </li>
                  <li>
                    <a href="#videos">
                      <FontAwesomeIcon icon={faLink} /> Neuroblastoma parent
                      conference videos
                    </a>
                  </li>
                  <li>
                    <a href="#online">
                      <FontAwesomeIcon icon={faLink} /> Links for community
                      support{" "}
                    </a>
                  </li>
                  <li>
                    <a href="#costs">
                      <FontAwesomeIcon icon={faLink} /> Managing treatment costs
                      and insurance
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section
        id="downloadables"
        style={{ backgroundColor: SassVars.paleBlue }}
      >
        <div className="row row--inner">
          <div className="columns">
            <h3 className="tc-barney jost-med h2">Downloadable resources</h3>
            <p>
              Some of these resources can also be ordered at no cost to you by
              visiting the{" "}
              <a href="/resources/request-resources/">Request Resources</a>{" "}
              page.
            </p>
          </div>
        </div>
        <div className="row row--inner align-justify lg_mt-2">
          <div className="columns large-12 small-12">
            <div className="row align-middle">
              <div className="columns small-12 large-shrink text-center">
                <img
                  src={NeuroblastomaGuide}
                  alt="Keep your child’s important medical information on hand for their neuroblastoma care team"
                  style={{ maxWidth: `171px` }}
                />
              </div>
              <div className="columns">
                <h3 className="h3 jost-med tc-viridian">
                  Braving Neuroblastoma Resource Guide
                </h3>
                <p>
                  Utilize this extensive guide to discover the educational
                  resources available to help support you on your neuroblastoma
                  journey.
                </p>
                <p className="lg_mt-2">
                  <a
                    href="/Braving_NB_Resource_Guide.pdf"
                    data-gtm-event-category="PDF"
                    data-gtm-event-action="Download"
                    data-gtm-event-label="Braving Neuroblastoma Resource Guide"
                    target="_blank"
                    rel="noreferrer"
                    className="btn--link no-ext"
                  >
                    Download Braving Neuroblastoma Resource Guide{" "}
                    <FontAwesomeIcon icon={faDownload} />
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="columns large-12 small-12 lg_mt-2 mt-2">
            <div className="row align-middle">
              <div className="columns small-12 large-shrink text-center">
                <img
                  src={fastFacts}
                  alt="Keep your child’s important medical information on hand for their neuroblastoma care team"
                  style={{ maxWidth: `171px` }}
                />
              </div>
              <div className="columns">
                <h3 className="h3 jost-med tc-viridian">
                  Fast Facts for Your Child
                </h3>
                <p>
                  Be sure those caring for your child have all of the important
                  medical information that they need in case of an emergency.
                </p>
                <p className="lg_mt-2">
                  <a
                    href="/fast-facts.pdf"
                    data-gtm-event-category="PDF"
                    data-gtm-event-action="Download"
                    data-gtm-event-label="Fast Facts for Your Child"
                    target="_blank"
                    rel="noreferrer"
                    className="btn--link no-ext"
                  >
                    Download Fast Facts for Your Child{" "}
                    <FontAwesomeIcon icon={faDownload} />
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="columns large-12 small-12 lg_mt-2 mt-2">
            <div className="row align-middle">
              <div className="columns small-12 large-shrink text-center">
                <img
                  src={drQuestions}
                  alt="Frequently asked questions to discuss with your child’s neuroblastoma doctor"
                  style={{ maxWidth: `171px` }}
                />
              </div>
              <div className="columns">
                <h3 className="h3 jost-med tc-viridian">
                  Questions to Ask Your Doctor
                </h3>
                <p>
                  Prepare for conversations with your healthcare team and ask
                  important questions about your child’s care at each step of
                  the journey.
                </p>
                <p className="lg_mt-2">
                  <a
                    href="/questions-to-ask-your-doctor-v7.pdf"
                    data-gtm-event-category="PDF"
                    data-gtm-event-action="Download"
                    data-gtm-event-label="Questions to Ask Your Doctor"
                    target="_blank"
                    rel="noreferrer"
                    className="btn--link no-ext"
                  >
                    Download Questions to Ask Your Doctor{" "}
                    <FontAwesomeIcon icon={faDownload} />
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="columns large-12 small-12 lg_mt-2 mt-2">
            <div className="row align-middle">
              <div className="columns small-12 large-shrink text-center">
                <img
                  src={thumbResource}
                  alt="Coping with Childhood Cancer Guide"
                  style={{ maxWidth: `171px` }}
                />
              </div>
              <div className="columns">
                <h3 className="h3 jost-med tc-viridian">
                  Coping with Childhood Cancer Guide
                </h3>
                <p>
                  A step-by-step guide to help set a good foundation for you and
                  your child's mental health throughout your journey. This guide
                  takes you from initial diagnosis to after treatment, with
                  considerations to help you cope with childhood cancer and
                  advocate for resources that may be available to you. Use this
                  guide as you navigate the childhood cancer journey.
                </p>
                <p className="lg_mt-2">
                  <a
                    href="/Mental_Health_Companion_Piece.pdf"
                    data-gtm-event-category="PDF"
                    data-gtm-event-action="Download"
                    data-gtm-event-label="Download Coping with Childhood Cancer Guide"
                    target="_blank"
                    rel="noreferrer"
                    className="btn--link no-ext"
                  >
                    Download Coping with Childhood Cancer Guide{" "}
                    <FontAwesomeIcon icon={faDownload} />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row row--inner align-justify lg_mt-2">
          <div className="columns large-12 small-12">
            <div className="row">
              <div className="columns small-12 large-shrink text-center">
                <img
                  src={bigAdventures}
                  alt="The Big Adventures of Little Skivolo"
                  style={{ maxWidth: `171px` }}
                />
              </div>
              <div className="columns">
                <h3 className="h3 jost-med tc-viridian">
                  The Big Adventures of Little Skivolo
                </h3>
                <p>
                  <em>The Big Adventures of Little Skivolo</em> is a book about
                  what a child can expect when they are diagnosed with
                  neuroblastoma. You can use this book to help explain
                  neuroblastoma to your child, siblings, and community members.
                  Skivolo learns about the different tests he will have in the
                  hospital and the people on the healthcare team who look after
                  him.
                </p>
                <p className="lg_mt-2">
                  <a
                    href="/BigAdventures_English.pdf"
                    data-gtm-event-category="PDF"
                    data-gtm-event-action="Download"
                    data-gtm-event-label="The Big Adventures of Little Skivolo (ENG)"
                    target="_blank"
                    rel="noreferrer"
                    className="btn--link no-ext"
                  >
                    Download in English <FontAwesomeIcon icon={faDownload} />
                  </a>
                  <a
                    href="/BigAdventures_Spanish.pdf"
                    data-gtm-event-category="PDF"
                    data-gtm-event-action="Download"
                    data-gtm-event-label="The Big Adventures of Little Skivolo (SPA)"
                    target="_blank"
                    rel="noreferrer"
                    className="btn--link no-ext"
                  >
                    Descargar en Español <FontAwesomeIcon icon={faDownload} />
                  </a>
                  <a
                    href="/BigAdventures_French.pdf"
                    data-gtm-event-category="PDF"
                    data-gtm-event-action="Download"
                    data-gtm-event-label="The Big Adventures of Little Skivolo (FRE)"
                    target="_blank"
                    rel="noreferrer"
                    className="btn--link no-ext"
                  >
                    Télécharger en Français{" "}
                    <FontAwesomeIcon icon={faDownload} />
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="columns large-12 small-12 lg_mt-4 mt-4">
            <div className="row">
              <div className="columns small-12 large-shrink text-center">
                <img
                  src={antiBodyBook}
                  alt="The Next Big Adventure of Little Skivolo"
                  style={{ maxWidth: `171px` }}
                />
              </div>
              <div className="columns">
                <h3 className="h3 jost-med tc-viridian">
                  The Next Big Adventure of Little Skivolo
                </h3>
                <p>
                  <em>The Next Big Adventure of Little Skivolo</em> can help
                  your child understand{" "}
                  <Link
                    rel="glossary"
                    to="/resources/glossary/#antibody-therapy"
                  >
                    antibody therapy
                  </Link>{" "}
                  for neuroblastoma. The book follows Skivolo as he begins
                  antibody therapy and learns about what the medicine does
                  inside his body to fight his cancer.
                </p>
                <p className="lg_mt-2">
                  <a
                    href="/Next-Big-Adventures-English.pdf"
                    data-gtm-event-category="PDF"
                    data-gtm-event-action="Download"
                    data-gtm-event-label="The Next Big Adventure of Little Skivolo (ENG)"
                    target="_blank"
                    rel="noreferrer"
                    className="btn--link no-ext"
                  >
                    Download in English <FontAwesomeIcon icon={faDownload} />
                  </a>
                  <a
                    href="/Next-Big-Adventures-Spanish.pdf"
                    data-gtm-event-category="PDF"
                    data-gtm-event-action="Download"
                    data-gtm-event-label="The Next Big Adventure of Little Skivolo (SPA)"
                    target="_blank"
                    rel="noreferrer"
                    className="btn--link no-ext"
                  >
                    Descargar en Español <FontAwesomeIcon icon={faDownload} />
                  </a>
                  <a
                    href="/Next-Big-Adventures-French.pdf"
                    data-gtm-event-category="PDF"
                    data-gtm-event-action="Download"
                    data-gtm-event-label="The Next Big Adventure of Little Skivolo (FRE)"
                    target="_blank"
                    rel="noreferrer"
                    className="btn--link no-ext"
                  >
                    Télécharger en Français{" "}
                    <FontAwesomeIcon icon={faDownload} />
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="columns large-12 small-12 lg_mt-4 mt-4">
            <div className="row">
              <div className="columns small-12 large-shrink text-center">
                <img
                  src={bookOfFun}
                  alt="Skivolo’s Big Book of Fun"
                  style={{ maxWidth: `171px` }}
                />
              </div>
              <div className="columns">
                <h3 className="h3 jost-med tc-viridian">
                  Little Skivolo’s Big Book of Fun
                </h3>
                <p>
                  <em>Little Skivolo’s Big Book of Fun</em> is an activity and
                  coloring book for patients and their families. The coloring
                  activities, games, crafts, and puzzles in this book can keep
                  your child and their siblings engaged during hospital visits.
                </p>
                <p className="lg_mt-2">
                  <a
                    href="/big-book-english.pdf"
                    data-gtm-event-category="PDF"
                    data-gtm-event-action="Download"
                    data-gtm-event-label="Little Skivolo’s Big Book of Fun (ENG)"
                    target="_blank"
                    rel="noreferrer"
                    className="btn--link no-ext"
                  >
                    Download in English <FontAwesomeIcon icon={faDownload} />
                  </a>
                  <a
                    href="/big-book-french.pdf"
                    data-gtm-event-category="PDF"
                    data-gtm-event-action="Download"
                    data-gtm-event-label="Little Skivolo’s Big Book of Fun (FRE)"
                    target="_blank"
                    rel="noreferrer"
                    className="btn--link no-ext"
                  >
                    Télécharger en Français{" "}
                    <FontAwesomeIcon icon={faDownload} />
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="columns large-12 small-12 lg_mt-4 mt-4">
            <div className="row">
              <div className="columns small-12 large-shrink text-center">
                <img
                  src={zara}
                  alt="Zara Takes Off"
                  style={{ maxWidth: `171px` }}
                />
              </div>
              <div className="columns">
                <h3 className="h3 jost-med tc-viridian">Zara Takes Off</h3>
                <p>
                  <em>Zara Takes Off</em> &nbsp;is a book about the transition a
                  child and family go though once treatment has finished. Zara
                  learns what to expect with follow-up care while she launches
                  into new adventures.{" "}
                </p>
                <p>
                  Look for a special foreword from Leah Still, daughter of
                  former NFL player Devon Still. Just like Zara, Leah has
                  finished her neuroblastoma treatment and shares details about
                  her journey.
                </p>
                <p className="lg_mt-2">
                  <a
                    href="/Zara_Survivorship_Book_and_Foreword_PDF.pdf"
                    data-gtm-event-category="PDF"
                    data-gtm-event-action="Download"
                    data-gtm-event-label="Zara Takes Off (ENG)"
                    target="_blank"
                    rel="noreferrer"
                    className="btn--link no-ext"
                  >
                    Download in English <FontAwesomeIcon icon={faDownload} />
                  </a>
                  <a
                    href="/Zara_Takes_Off_Spanish.pdf"
                    data-gtm-event-category="PDF"
                    data-gtm-event-action="Download"
                    data-gtm-event-label="Zara Takes Off (SPA)"
                    target="_blank"
                    rel="noreferrer"
                    className="btn--link no-ext"
                  >
                    Descargar en Español <FontAwesomeIcon icon={faDownload} />
                  </a>
                  <a
                    href="/Zara_Takes_Off_French.pdf"
                    data-gtm-event-category="PDF"
                    data-gtm-event-action="Download"
                    data-gtm-event-label="Zara Takes Off (FRE)"
                    target="_blank"
                    rel="noreferrer"
                    className="btn--link no-ext"
                  >
                    Télécharger en Français{" "}
                    <FontAwesomeIcon icon={faDownload} />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section>
        <div className="row row--inner">
          <div className="columns small-12 mb-1 md_mb-1 lg_mb-1 mt-3 md_mt-3 lg_mt-3">
            <h3 className="h2 tc-barney jost-med">
              Treatment journey calendar
            </h3>
          </div>
          <div className="columns small-12">
            <div className="row row--inner">
              <div className="columns small-12 large-3 large-text-left text-center">
                <img
                  src={thumbJourneyCalendar}
                  alt="Treatment journey calendar"
                />
              </div>
              <div className="columns">
                <p>
                  This calendar helps families organize dosing schedules, record
                  milestones, and decorate memorable dates with fun stickers.
                </p>
                <p className="mt-1 md_mt-1 lg_mt-1">
                  <a href="/resources/request-resources/">
                    Request a calendar at no cost to you.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              Neuroblastoma Parent Videos
            </h3>
            <p>
              Find hope through one family’s story of resilience in the face of
              neuroblastoma.
            </p>
            <VideoPlayer
              embedId="871112390"
              data="h=bd44571455"
              title="Still Standing Together"
            />
          </div>
        </div>
      </Section>

      <Section style={{ backgroundColor: SassVars.paleBlue }}>
        <div className="row row--inner align-middle">
          <div className="column">
            <h3 className="h2 tc-barney jost-med">
              Neuroblastoma Clinician Videos
            </h3>
            <p>
              Learn more from a pediatric oncologist, nurse practitioner and
              social worker as they discuss points throughout the neuroblastoma
              journey.
            </p>
          </div>
        </div>

        <div className="row row--inner carousel">
          
          <div className="column carousel-upper-row">
            <VideoPlayer
              className='main-video'
              youtube={true}
              embedId={videoId}
              title={videoTitle}
              style={{
                width: "100%",
                aspectRatio: "16/9",
                margin: "0px ",
                display: "flex",
                alignItems: "flex-start",
              }}
            />
            {videos.map(({embedId,title, description}) => {
              if (embedId === videoId) { 
              return (
                <div className='video-content'>
                  <p>
                    <strong>Video Title: </strong>
                    {title}
                  </p>
                  <p>
                    <strong>Description: </strong>
                    {description}
                  </p>
                </div>)
             }
           })}
          </div>

          <div className="column carousel-bottom-row">
            {videos.map(({ title, embedId, imageSrc }, index) => (
              <>
                <div className={videoId == embedId ? "carousel-video-container active" : "carousel-video-container"}
                onClick={() => {
                  setVideoId(embedId)
                  setVideoTitle(title)
                }} key={embedId}
              >
                <strong className="video-title">
                 {index + 1}: {title}
                </strong>
                <div className="carousel-thumbnail">
                <img
                  alt={title}
                  src={imageSrc}
                  />
                </div>
              </div>
              </>
            ))}
          </div>

        </div>
      </Section>

      <Section id="videos" style={{ backgroundColor: SassVars.honeydew }}>
        <div className="row row--inner">
          <div className="columns">
            <h3 className="tc-barney jost-med h2">
              Neuroblastoma parent conference videos
            </h3>
            <p>
              {" "}
              Explore the video link below to watch neuroblastoma experts
              discuss points in the neuroblastoma journey.
            </p>
          </div>
        </div>
        <div className="row row--inner align-justify lg_mt-2">
          <div className="columns large-6 small-12">
            <div className="row align-middle">
              <div className="columns small-4 large-4 text-center mt-1">
                <img src={tvBlue} alt="Neuroblastoma Parent Global Symposium" />
              </div>
              <div className="columns small-8">
                <p>
                  <a
                    target="_blank"
                    data-gtm-event-category="External Link"
                    data-gtm-event-action="Click"
                    data-gtm-event-label="Neuroblastoma Parent Global Symposium"
                    rel="noreferrer"
                    href="https://www.youtube.com/channel/UCPf2wMnFK0YOZ2idAwqzWKA/featured "
                  >
                    Neuroblastoma Parent Global Symposium{" "}
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section id="online">
        <div className="row row--inner">
          <div className="columns">
            <h3 className="tc-barney jost-med h2">
              Links for community support
            </h3>
            <p>Discover more helpful resources below:</p>
            <ul className="ul--standard lg_mt-1">
              <li>
                <a
                  data-gtm-event-category="External Link"
                  data-gtm-event-action="Click"
                  data-gtm-event-label="Support for Families When a Child Has Cancer"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.cancer.gov/about-cancer/coping/caregiver-support/parents"
                >
                  {" "}
                  Support for Families When a Child Has Cancer{" "}
                  <sup>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </sup>
                </a>
              </li>
              <li>
                <a
                  data-gtm-event-category="External Link"
                  data-gtm-event-action="Click"
                  data-gtm-event-label="Children’s Oncology Group Family Handbook "
                  target="_blank"
                  rel="noreferrer"
                  href="https://childrensoncologygroup.org/index.php/cog-family-handbook"
                >
                  {" "}
                  Children’s Oncology Group Family Handbook{" "}
                  <sup>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </sup>
                </a>
              </li>
              <li>
                <a
                  data-gtm-event-category="External Link"
                  data-gtm-event-action="Click"
                  data-gtm-event-label="Adjusting to Cancer "
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.cancer.gov/about-cancer/coping/adjusting-to-cancer/changes-for-family"
                >
                  {" "}
                  Adjusting to Cancer{" "}
                  <sup>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </sup>
                </a>
              </li>
              <li>
                <a
                  data-gtm-event-category="External Link"
                  data-gtm-event-action="Click"
                  data-gtm-event-label="The NANT Foundation"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.nant.org/"
                >
                  {" "}
                  The NANT Foundation (New Approaches to Neuroblastoma Therapy){" "}
                  <sup>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </sup>
                </a>
              </li>
              <li>
                <a
                  data-gtm-event-category="External Link"
                  data-gtm-event-action="Click"
                  data-gtm-event-label="Coalition Against Childhood Cancer "
                  target="_blank"
                  rel="noreferrer"
                  href="https://cac2.org/"
                >
                  {" "}
                  Coalition Against Childhood Cancer{" "}
                  <sup>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </sup>
                </a>
              </li>
              <li>
                <a
                  data-gtm-event-category="External Link"
                  data-gtm-event-action="Click"
                  data-gtm-event-label="Search for neuroblastoma clinical trials "
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.cancer.gov/about-cancer/treatment/clinical-trials/search"
                >
                  {" "}
                  Search for neuroblastoma clinical trials{" "}
                  <sup>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </sup>
                </a>
              </li>

              <li>
                <a
                  data-gtm-event-category="External Link"
                  data-gtm-event-action="Click"
                  data-gtm-event-label="The Hope Portal"
                  target="_blank"
                  rel="noreferrer"
                  href="https://cac2.org/interest-groups/family-support/hope-portal/"
                >
                  {" "}
                  The Hope Portal{" "}
                  <sup>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </sup>
                </a>
              </li>

              <li>
                <a
                  data-gtm-event-category="External Link"
                  data-gtm-event-action="Click"
                  data-gtm-event-label="The Psychosocial Standards of Care"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.mattiemiracle.com/standards"
                >
                  {" "}
                  The Psychosocial Standards of Care{" "}
                  <sup>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </sup>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Section>
      <Section id="costs" style={{ backgroundColor: SassVars.paleBlue }}>
        <div className="row row--inner align-justify align-middle">
          <div className="columns">
            <h3 className="tc-barney jost-med h2">
              Managing neuroblastoma treatment costs and insurance
            </h3>
            <p>
              The cost of cancer treatment can often be a source of stress for
              families. It is helpful to meet with the oncology social worker to
              discuss your concerns. They can help you assess your financial
              needs, help evaluate your health insurance coverage, and connect
              you with resources like:
            </p>
          </div>
          <div className="columns large-4 large-text-left small-12 text-center">
            <FancyImage
              alt="Managing neuroblastoma treatment costs and insurance"
              url={managing}
            />
          </div>
        </div>
        <div className="row row--inner mt-3">
          <div className="columns">
            <ul className="ul--dotted">
              <li>
                <strong>Family and medical leave (also called FMLA)</strong>
                <ul>
                  <li>
                    Many working caregivers may need to be away from work for
                    periods of time to take care of their child. Your social
                    worker can help navigate leave
                  </li>
                </ul>
              </li>
              <li>
                <strong>Social Security</strong>
                <ul>
                  <li>
                    Neuroblastoma treatment can be a long journey. Your social
                    worker can help with the application process to determine if
                    your child is eligible
                  </li>
                </ul>
              </li>
              <li>
                <strong>Other financial resources</strong>
                <ul>
                  <li>
                    Medical bills can add up quickly. If you do not have health
                    insurance, your social worker may help your child get health
                    insurance benefits. However, health insurance doesn’t always
                    cover all costs. Your social worker can help find other
                    resources, such as co-pay or medication assistance
                  </li>
                  <li>
                    There are local and national organizations that also help
                    with financial concerns, ranging from medical cost
                    assistance, housing assistance, and travel assistance, to
                    general financial help. Your social worker can help connect
                    you
                  </li>
                </ul>
              </li>
            </ul>
            <p className="mt-2">
              <Link to="/resources/support-groups/">
                To learn more about organizations that may be able to help with
                the cost of your child’s treatment, go to the Neuroblastoma
                Support and Advocacy Groups page.{" "}
              </Link>
            </p>
          </div>
        </div>
      </Section>
      <InteriorPageFooter>
        <ExploreCard
          title="Skivolo's Kid Zone"
          lead="Explore the neuroblastoma journey through the eyes of Skivolo, a young red panda, and his family by downloading storybooks and coloring apps."
          url="/resources/skivolos-kid-zone/"
        />
      </InteriorPageFooter>
    </Layout>
  )
}

export default DownloadableResourcesPage
